import * as React from 'react';
import { Flex, Box, Text, useBreakpointValue, Stack } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import FadeIn from '../components/FadeIn';

export default function WhatsChokerJoker() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Flex
      px={4}
      pt={8}
      w="full"
      justify="space-between"
      mx="auto"
      direction={isMobile ? 'column' : 'row'}
    >
      <Flex justify="center" align="center">
        <Box py={4}>
          <FadeIn>
            <Stack spacing={8} mb={20}>
              <Text
                fontFamily="Haetten, Sawarabi Gothic, sans-serif"
                letterSpacing={2}
                fontWeight={400}
                fontSize={{ base: '4xl', md: '4xl', xl: '6xl' }}
                textAlign="center"
              >
                Collection from Chords
              </Text>
              <StaticImage
                src="../images/whatscj_1.png"
                alt="What's CJ 1"
                quality={60}
                layout="fullWidth"
              />
              <Text
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              >
                音楽を聴くと、嬉しさや哀しさ…それどころか嬉しい中にほのかな郷愁が香ったり、「哀しいけれど前を向こう！」と思わせてくれたり…
                <br />
                「あいうえお…」は50音、「A、B、C…」は26音、言葉を駆使した名作小説、名作童話など世の中には数多くございます。
                <br />
                それと比べて「ド、レ、ミ…」は、半音あわせても12音しか無いんです。
                <br />
                でも言葉たちにはない、音たちだけの武器…それは組み合わせる事で何倍にも威力を増す
                「和音（コード）の響き」があるという事。
                <br />
                それを駆使した名曲たち…きっと皆さまのお気に入りの一曲がございますよね？
              </Text>
            </Stack>
          </FadeIn>
          <FadeIn>
            <Stack spacing={8} mb={20}>
              <Text
                fontFamily="Haetten, Sawarabi Gothic, sans-serif"
                letterSpacing={2}
                fontWeight={400}
                fontSize={{ base: '4xl', md: '4xl', xl: '6xl' }}
                textAlign="center"
              >
                Inspired by Music
              </Text>
              <StaticImage
                src="../images/whatscj_3.png"
                alt="What's CJ 2"
                quality={60}
                layout="fullWidth"
              />
              <Text
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              >
                楽曲の中で奏でられる和音（コード）の響きにインスパイアされ、それをチョーカーとして形に表してみたら？そんなチョーカーを首元に飾れたら素敵ではないだろうか？との想いから「Choker
                Joker」は誕生しました。
                <br />
                皆さまの心に棲んでいる、お気に入りのあの一曲。
                <br />
                聴いた瞬間にあの時、あの場面、あの心情、あの情景、あの匂いまでをも思い起こさせるような楽曲がきっと心にあるハズです。
              </Text>
            </Stack>
          </FadeIn>
          <FadeIn>
            <Stack spacing={8}>
              <Text
                fontFamily="Haetten, Sawarabi Gothic, sans-serif"
                letterSpacing={2}
                fontWeight={400}
                fontSize={{ base: '4xl', md: '4xl', xl: '6xl' }}
                textAlign="center"
              >
                Parts Select with Variation
              </Text>
              <StaticImage
                src="../images/whatscj_2.png"
                alt="What's CJ 3"
                quality={60}
                layout="fullWidth"
              />
              <Text
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              >
                国内外に無数に存在するバリエーション豊かな様々なパーツたちを眺めていると、「ふと」思い立つテーマ。
                <br />
                音たちを組み合わせる和音（コード）のように、創造しながら組み合わせていきます。
                <br />
                そして、テーマはより具現化し「こんな楽曲に合うハズだ」「こんな音楽が流れる場面にきっとマッチする」。そこまで思えれば、チョーカーの完成は間近です。
                <br />
                普段、気軽に身に付けられるカジュアルなアイテムから、ステージに立つアナタをより際立たせるアイテムまで。
                <br />
                商品名は、創造から得た方向性をカテゴライズし、イメージに合うであろう正にコード名を冠しております。
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              >
                音楽を聴かれる方も、音楽をプレイされる方も。
                <br />
                「Choker
                Joker」は、音楽を愛する全ての皆様にきっと喜んで頂けるであろうアイテムを随意ラインナップして参ります。
              </Text>
            </Stack>
          </FadeIn>
        </Box>
      </Flex>
    </Flex>
  );
}
