import * as React from 'react';
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { maxW } from '../assets/config';
import { StaticImage } from 'gatsby-plugin-image';

export default function KeyVisualWcj() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box bg="black" px={4}>
      <Flex
        maxW={maxW}
        mx="auto"
        pt={isMobile ? 0 : 8}
        mb={isMobile ? 8 : 0}
        justify={isMobile ? 'center' : 'space-between'}
        alignItems="center"
      >
        <Flex direction="column">
          <Text
            color="cyan"
            fontSize={{ base: '6xl', md: '9xl' }}
            fontFamily="Haetten, Sawarabi Gothic, sans-serif"
            letterSpacing={2}
            fontWeight={400}
          >
            What's CJ?{isMobile ? <br /> : null}
            <Text
              as="span"
              color="white"
              fontSize={{ base: '4xl', md: '6xl' }}
              ml={isMobile ? 0 : 8}
              textShadow="2px 3px 2px gray"
            >
              <u>Choker Joker's Concept</u>
            </Text>
          </Text>
        </Flex>
        <Box w="20%">
          <StaticImage src="../images/cj-logo2.png" alt="logo" />
        </Box>
      </Flex>
      <Box maxW={isMobile ? '80%' : maxW} mx="auto">
        <Text
          fontWeight={400}
          fontSize={{ base: '2xl', md: '3xl', xl: '4xl' }}
          color="white"
          pb={isMobile ? 8 : 20}
        >
          インスピレーション、{isMobile ? <br /> : null}
          生まれるまでのそのストーリー
        </Text>
      </Box>
    </Box>
  );
}
