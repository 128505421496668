import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { maxW } from '../assets/config';
import KeyVisualWcj from '../components/KeyVisualWcj';
import Layout from '../components/layout';
import Seo from '../components/seo';
import WhatsChokerJoker from '../features/WhatsChokerJoker';
import JsonLd from '../components/JsonLd';

const WhatsCjPage = () => {
  return (
    <Layout full={true}>
      <Seo
        title="What's CJ?"
        description="ChokerJokerの思い、コンセプトについて紹介します。"
      />
      <JsonLd
        title="What's CJ?"
        description="ChokerJokerの思い、コンセプトについて紹介します。"
      />
      <KeyVisualWcj />
      <Box maxW={maxW} mx="auto">
        <WhatsChokerJoker />
      </Box>
    </Layout>
  );
};

export default WhatsCjPage;
